import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 获取列表分页
  getListPaged: "/api/WarehouseService/InWarehousePlan/GetListPaged",
  // 获取模板地址
  getGenerateTemplate: "/api/WarehouseService/InWarehousePlan/GenerateTemplate",
  // 导入产品
  uploadParseExcelData: "/api/WarehouseService/InWarehousePlan/ParseExcelData",
  // 新增入库计划
  createInPlan: "/api/WarehouseService/InWarehousePlan/Create",
  // 修改入库计划
  updateInPlan: "/api/WarehouseService/InWarehousePlan/Update",
  // 返回第一步的查询
  getFirstStep: "/api/WarehouseService/InWarehousePlan/GetFirstStep",
  // 存储仓详情（新）
  getStorageDetail: "/api/WarehouseService/InWarehousePlan/GetStorage",
  // 配送仓详情（新）
  getDelegate: "/api/WarehouseService/InWarehousePlan/GetDelegate",
  // 修改计划名称
  updateInWarehousePlanName: "/api/WarehouseService/InWarehousePlan/UpdateInWarehousePlanName",
  // 修改用户自定义箱号
  updateUserSelfBoxNo: "/api/WarehouseService/Box/UpdateUserSelfBoxNo",
  // 获取打印箱标地址
  getInWarehousePlanBoxPrint: "/api/WarehouseService/Print/GetInWarehpusePlanBoxPrint/inplanbox.pdf",
  // 获取批量打印箱标地址
  getInWarehpusePlanBoxBatchPrint: "/api/WarehouseService/Print/GetInWarehpusePlanBoxBatchPrint/inplanboxbatch.pdf",
  // 确认计划（第二步）
  updateConfirmPlan: "/api/WarehouseService/InWarehousePlan/UpdateConfirm",
  // 获取费用
  getInWarehousePlanFee: "/api/WarehouseService/InWarehousePlan/GetFee",
  // 支付
  payInWarehousePlan: "/api/WarehouseService/InWarehousePlan/PayPlan",
  // 发运
  updateTransInfo: "/api/WarehouseService/InWarehousePlan/UpdateTransInfo",
  // 获取物流跟踪信息
  getAllTraceList: "/api/WarehouseService/InWarehousePlan/GetAllTraceList",
  // 更新物流单号
  updateInWarehousePlanTraceNo: "/api/WarehouseService/InWarehousePlan/UpdateInWarehousePlanTraceNo",
  // 查看产品统计详情
  getProductStatistics: "/api/WarehouseService/InWarehousePlan/GetProductStatistics",
  // 取消入库计划
  updateCancelPlan: "/api/WarehouseService/InWarehousePlan/UpdateCancelPlan",
  // 上传异常证明文件
  uploadSupportingDocuments: "/api/BasicDataService/AliyunOSS/UploadToTemp",
  // 异常处理
  updateProductErrorStatus: "/api/WarehouseService/InWarehousePlan/UpdateProductErrorStatus ",
}

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};

export const getGenerateTemplate = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.getGenerateTemplate);
};

export const uploadParseExcelData = (data) => {
  return client.request({
    url: Api.uploadParseExcelData,
    data,
    method: "POST",
  });
};

export const createInPlan = (data) => {
  return client.request({
    url: Api.createInPlan,
    data,
    method: "POST",
  });
};

export const updateInPlan = (data) => {
  return client.request({
    url: Api.updateInPlan,
    data,
    method: "POST",
  });
};

export const getFirstStep = (params) => {
  return client.request({
    url: Api.getFirstStep,
    params,
    method: "GET",
  });
};

export const getStorageDetail = (params) => {
  return client.request({
    url: Api.getStorageDetail,
    params,
    method: "GET",
  });
};

export const getDelegate = (params) => {
  return client.request({
    url: Api.getDelegate,
    params,
    method: "GET",
  });
};

export const updateInWarehousePlanName = (data) => {
  return client.request({
    url: Api.updateInWarehousePlanName,
    data,
    method: "POST",
  });
};

export const updateUserSelfBoxNo = (data) => {
  return client.request({
    url: Api.updateUserSelfBoxNo,
    data,
    method: "POST",
  });
};

export const getInWarehousePlanBoxPrint = (params) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getInWarehousePlanBoxPrint);
  url = urlHelper.addQueryString(url, "id", params.id);
  url = urlHelper.addQueryString(url, "detailId", params.detailId);
  return url;
};

export const getInWarehpusePlanBoxBatchPrint = (params) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getInWarehpusePlanBoxBatchPrint);
  url = urlHelper.addQueryString(url, "id", params.id);
  return url;
};

export const updateConfirmPlan = (data) => {
  return client.request({
    url: Api.updateConfirmPlan,
    data,
    method: "POST",
  });
};

export const getInWarehousePlanFee = (params) => {
  return client.request({
    url: Api.getInWarehousePlanFee,
    params,
    method: "GET",
  });
};

export const payInWarehousePlan = (data) => {
  return client.request({
    url: Api.payInWarehousePlan,
    data,
    method: "POST",
  });
};

export const updateTransInfo = (data) => {
  return client.request({
    url: Api.updateTransInfo,
    data,
    method: "POST",
  });
};

export const getAllTraceList = (params) => {
  return client.request({
    url: Api.getAllTraceList,
    params,
    method: "GET",
  });
};

export const updateInWarehousePlanTraceNo = (data) => {
  return client.request({
    url: Api.updateInWarehousePlanTraceNo,
    data,
    method: "POST",
  });
};

export const getProductStatistics = (params) => {
  return client.request({
    url: Api.getProductStatistics,
    params,
    method: "GET",
  });
};

export const updateCancelPlan = (data) => {
  return client.request({
    url: Api.updateCancelPlan,
    data,
    method: "POST",
  });
};

export const uploadSupportingDocuments = (data) => {
  return client.request({
    url: Api.uploadSupportingDocuments,
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateProductErrorStatus = (data) => {
  return client.request({
    url: Api.updateProductErrorStatus,
    data,
    method: "POST",
  });
};