<template>
  <div>
    <a-row type="flex" justify="end">
      <a-col>
        <div style="min-width: 500px" v-if="fees">
          <div style="border-bottom: 1px solid #ccc">
            {{ $t("warehouse.charge") }}
          </div>
          <div class="p-2 pb-2">
            <!-- 费用明细 -->
            <a-row
              :gutter="[8, 8]"
              type="flex"
              align="top"
              v-for="(item, index) in fees"
              :key="index"
            >
              <a-col style="text-align: right" :span="14">
                <div v-if="item.remark">
                  <a-popover>
                    <template #content>
                      <div style="max-width: 400px">
                        <a-typography-text :content="item.remark" />
                      </div>
                    </template>
                    <QuestionCircleOutlined />
                    {{ $t($enumLangkey("orderLogisticsFee", item?.feeType)) }}:
                  </a-popover>
                </div>
                <div v-else>
                  {{ $t($enumLangkey("orderLogisticsFee", item?.feeType)) }}:
                </div>
              </a-col>
              <a-col :span="10">
                <span v-if="item?.checkValue == null" class="mr-3">
                  {{ item?.symbol }}{{ $filters.amountToFixed2(item?.value) }}
                </span>
                <a-typography-text
                  type="secondary"
                  delete
                  v-else-if="item?.checkValue != item?.value && item?.value > 0"
                  class="mr-3"
                >
                  {{ item?.symbol }}{{ $filters.amountToFixed2(item?.value) }}
                </a-typography-text>

                <span v-if="!(item?.checkValue == null)">
                  {{ item?.symbol }}{{ $filters.amountToFixed2(item?.checkValue) }}
                </span>
              </a-col>
            </a-row>
          </div>
          <div
            style="border-bottom: 1px solid #ccc"
            v-if="showTotal && fees?.length > 0"
          ></div>
          <!-- 合计 -->
          <a-row class="pt-2" :gutter="[8, 8]" type="flex" align="top">
            <a-col style="text-align: right" :span="14">
              {{ $t("warehouse.total_prices") }}:
            </a-col>
            <a-col :span="10">
              <span v-if="totalFee?.checkValue == null" class="mr-3">
                {{ totalFee?.symbol }}{{ $filters.amountToFixed2(totalFee?.value) }}
              </span>
              <a-typography-text
                type="secondary"
                delete
                v-else-if="
                  totalFee?.checkValue != totalFee?.value && totalFee?.value > 0
                "
                class="mr-3"
              >
                {{ totalFee?.symbol }}{{ $filters.amountToFixed2(totalFee?.value) }}
              </a-typography-text>

              <span v-if="!(totalFee?.checkValue == null)">
                {{ totalFee?.symbol }}{{ $filters.amountToFixed2(totalFee?.checkValue) }}
              </span>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { Row, Col, Form, Typography, Popover } from "ant-design-vue";
import { computed } from "vue";
export default {
  name: "CFee",
  props: {
    totalFee: {
      default: () => ({
        symbol: null, //必传
        value: null, //金额  //必传
        checkValue: null, //核验后金额 //可空
      }),
    },
    fees: {
      type: Array,
      default: () => [],
      //数组里面的对象字段如下:
      // [{
      //     "value": 0,//必传
      //     "checkValue": null,//可空
      //     "feeType": 230,//必传
      //     "symbol": "¥",//必传
      //     "remark": ,//非必传
      // }]
    },
    // 是否显示合计
    showTotal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    // const isShowFee = computed(() => {
    //   return props?.fees?.some((x) => {
    //     return x?.value > 0 || x?.checkValue > 0;
    //   });
    // });

    return {
      // isShowFee,
    };
  },
  components: {
    ARow: Row,
    ACol: Col,
    AFormItem: Form.Item,
    ATypographyText: Typography.Text,
    APopover: Popover,
  },
};
</script>
