<template>
  <a-tag v-if="productKindCount == 1" color="success">
    {{ $t("common.single") }}
  </a-tag>
  <a-tag v-if="productKindCount > 1" color="warning">
    {{ $t("common.mixed") }}
  </a-tag>
</template>

<script>
import { Tag } from "ant-design-vue";
import { defineComponent, toRefs } from "vue";
export default defineComponent({
  name: "CTagMixedOrSingle",
  components: {
    ATag: Tag,
  },
  props: {
    // 产品种类数量: 0 不显示; 1 单一的;  >1  混装的
    productKindCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style lang="less" scoped></style>
