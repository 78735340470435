<template>
  <a-spin :spinning="data.saving"
          size="small">
    <a-typography-paragraph
                            :ellipsis="true ? { tooltip: data.userDefinedBoxNo } : false"
                            :style=" { width: showBoxNoWidth } "
                            v-model:content="data.userDefinedBoxNo"
                            :editable="{
                                  tooltip: true,
                                  editing: data.editing,
                                  maxlength: 64,
                                  onEnd: onEnd,
                                  onCancel: onCancel
                                }"
                            >
      <template v-slot:editableIcon>
        <EditOutlined  @click="data.editing=true"/>
      </template>
      <template v-slot:editableTooltip>{{$t('warehouse.set_user_defined_box_no')}}</template>
    </a-typography-paragraph>
  </a-spin>
</template>
<script>
import { updateUserDefinedBoxNo } from "../../api/modules/common/index";
import { Spin, Button, Input, message, Tooltip, Typography } from "ant-design-vue";
import { reactive, watch, } from "vue";
import { useI18n } from "vue-i18n/index";

export default {
  name: "UserDefinedBoxNo",
  components: {
    ASpin: Spin,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    ATooltip: Tooltip,
    ATypographyParagraph: Typography.Paragraph,
  },
  props: {
    userDefinedBoxNo: {
      type: String,
      default: "",
    },
    boxId: {
      type: String,
      required: true,
      default: "",
    },
    showBoxNoWidth: {
      type: String,
      required: false,
      default: "130px",
    },
    emits: ['userDefinedBoxNo'],
  },
  setup (props,{emit}) {
    const vueI18n = useI18n({ useScope: "global" });

    const data = reactive({
      saving: false,
      editBoxNo: false,
      userDefinedBoxNo: props.userDefinedBoxNo||"",
      editing: false,
      catcheCustomNo: props.userDefinedBoxNo||"",
    })

    watch(()=>props.userDefinedBoxNo, (newVal)=>{
      data.userDefinedBoxNo = newVal ||"";
      data.catcheCustomNo = newVal ||"";
      data.editing = false;
    })

    const onEnd = () => {
      let reg = /^$|^[0-9A-Za-z\-\\\/\\_]{1,35}$/;
      if (!data.userDefinedBoxNo) {
        data.userDefinedBoxNo = '';
      }

      if (data.userDefinedBoxNo.length > 35) {
        message.error(vueI18n.t("common.the_input_length_is_too_long"));
        return false
      }

      if (!reg.test(data.userDefinedBoxNo)) {
        message.error(vueI18n.t("common.input_is_not_in_the_correct_format"));
        return false
      }
      if (data.userDefinedBoxNo == data.catcheCustomNo) {
        data.editing = false;
        return false
      }
      data.saving = true;
      updateUserDefinedBoxNo(props.boxId, data.userDefinedBoxNo)
        .then((res) => {
          data.catcheCustomNo = data.userDefinedBoxNo;
          data.saving = false;
          data.editing = false;
          message.success(vueI18n.t("common.set_user_defined_box_no_succeed"));
          emit("update:userDefinedBoxNo", data.catcheCustomNo)
        })
        .catch(() => {
          data.saving = false;
        })
    }
    const onCancel = ()=>{
        data.editing = false;
    }

    return {
      data,
      onEnd,
      onCancel,
    };
  },
};
</script>

<style lang="less">
:deep(.input-group-mid-number) {
  border-radius: 0;
  width: 100%;
}
</style>
