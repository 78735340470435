  <template>
  <a-modal v-model:visible="visible"
           centered
           width="800px"
           :maskClosable="false"
           :keyboard="false"
           :closable="false"
           :footer="null">
    <a-row type="flex"
           justify="center">
      <a-col>
        <h3><strong>{{$t('logistics.pay')}}</strong></h3>
      </a-col>
    </a-row>

    <a-spin :spinning="payModal.loading||loading">

      <a-row type="flex"
             justify="space-between">
        <a-col>
          <span v-if="props.payModalTitleType==2">{{$t('logistics.associated_order_number')}}: </span>
          <span v-else-if="props.payModalTitleType==4">{{$t('logistics.shipping_plan')}}: </span>
          <span v-else-if="props.payModalTitleType==5">{{$t('warehouse.container_no')}}: </span>
          <span v-else-if="props.payModalTitleType==7">{{$t('warehouse.warehousing_reservation_number')}}: </span>
          <span v-else-if="props.payModalTitleType==9">{{$t('warehouse.in_warehouse_plan_no')}}: </span>
          <span v-if="props.orderNo">{{props.orderNo}}</span>
        </a-col>
        <a-col>
          {{$t('logistics.pay_sum')}}: {{$filters.formatCurrencyAmount(props.unpaidSymbol ,props.unpaidSum,false)}}
        </a-col>
      </a-row>

      <a-row>
        <a-col>
          <a-radio-group v-model:value="payModal.account.selectedPayWay"
                         class="mt-2">
            <a-radio :value="1">
              <span class="mr-5">{{$t('logistics.account_balance_pay')}}</span>
            </a-radio>
            <span>
              {{$t('logistics.account_balance')}}:
              <span :class="payModal.account.balance>0?'text-success':'text-error'">
                {{$filters.formatCurrencyAmount(payModal.account.symbol, payModal.account.balance, false)}}
              </span>
              <span v-if="payModal.account.balance<props.unpaidSum"
                    class="ml-2 text-error">
                {{$t('logistics.not_sufficient_funds')}}
              </span>
              <span v-if="payModal.account.overdraftLimit>0"
                    class="ml-2 ">
                {{$t('logistics.facility_extent')}}:
                {{$filters.formatCurrencyAmount(payModal.account.symbol,payModal.account.overdraftLimit,false)}}
              </span>
              <a-button size="small"
                        class="ml-3"
                        @click="handleGoTopUp">{{$t('common.top_up')}}</a-button>
              <a-button size="small"
                        @click="handleShowPayOrder"
                        class="ml-2">{{$t('common.refresh')}}</a-button>
            </span>
          </a-radio-group>
        </a-col>
      </a-row>

      <a-row type="flex"
             justify="center"
             class="mt-4">
        <a-col>
          <a-button @click="handleOrderPay(false)" v-if="buttonTextType==1">
            <span>{{$t('common.pay_later')}}</span>
          </a-button>
           <a-button @click="handleCancel" v-else>
            <span >{{$t('common.cancel')}}</span>
          </a-button>
        </a-col>
        <a-col>
          <a-button type="primary"
                    class="ml-5"
                    @click="handleOrderPay(true)">
            <span v-if="buttonTextType==1">{{$t('common.immediate_payment')}}</span>
            <span v-else>{{$t('common.confirm')}}</span>
          </a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { reactive, watch } from "vue";
import {
  Row, Col, Radio, Spin, Table,
  Button, Form, Modal, message
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { getAccountBalance } from "../../api/modules/common/index";
import { useRouter } from 'vue-router';

export default ({
  name: "Payment",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ASpin: Spin,
  },
  props: {
    currencyId: {
      type: String,
      required: true,
      default: ""
    },
    orderNo: {
      type: String,
      required: false,
      default: ""
    },
    unpaidSymbol: {
      type: String,
      required: true,
      default: ""
    },
    unpaidSum: {
      type: Number,
      required: true,
      default: 0
    },
    payModalTitleType: {
      type: Number,
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    buttonTextType:{
      type: Number,
      required: false,
      default: 0
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const payModal = reactive({
      loading: false,
      account: {
        balance: 0,
        symbol: null,
        overdraftLimit: null,
        selectedPayWay: 1
      }
    })

    const handleShowPayOrder = async (orderNo, currencyId) => {
      payModal.loading = true;
      let pra = {
        "currencyId": props.currencyId
      };
      getAccountBalance(pra)
        .then(({ result }) => {
          if (result) {
            payModal.account.balance = result.amount;
            payModal.account.overdraftLimit = result.overdraftFacility;
            payModal.account.symbol = result.currencySymbol;
          }
        })
        .finally(() => {
          payModal.loading = false;
        })
    }

    const handleOrderPay = (isImmediatePayment) => {
      if (!payModal.account.selectedPayWay) {
        message.error(vueI18n.t("logistics.please_select_payment_method"));
        return false
      }
      emit('handlePayFee', payModal.account.selectedPayWay,isImmediatePayment)
    }

    const handleGoTopUp = () => {
      emit('update:visible', false)
      router.push({ name: "finance_recharge_record" });
    }

    const handleCancel = () => {
      emit('update:visible', false)
    }

    return {
      props,
      payModal,
      handleShowPayOrder,
      handleOrderPay,
      handleGoTopUp,
      handleCancel
    };
  }
})
</script>

<style lang="less" scoped>
</style>