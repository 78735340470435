import printJs from "print-js";
import siteOptions from "../siteconfigs/index";
import { getToken, getLang } from "../hooks/login/index";
import { headerKeys, nameKeys } from "../consts/index";
import urlHelper from "./urlHelper.js";
// npm install print-js --save

const isApiHost = function (url) {
  if (url) {
    if (siteOptions.baseURL) {
      return url.toLowerCase().indexOf(siteOptions.baseURL.toLowerCase()) == 0;
    }
    //todo: 需要更全面的判定，但当前项目不需要
  }
  return false;
};

const chunkFromCharCode = (arr) => {
  let res = "";
  if (arr && arr.length > 0) {
    const chunkSize = 8 * 1024;
    for (var i = 0; i < arr.length / chunkSize; i++) {
      res += String.fromCharCode(
        ...arr.slice(i * chunkSize, (i + 1) * chunkSize)
      );
    }
    res += String.fromCharCode(...arr.slice(i * chunkSize));
  }
  return res;
};

const print = async (urlOrPath, printType) => {
  return new Promise((resolve, reject) => {
    if (
      printType == "image" ||
      (!printType && urlHelper.isImageUrl(urlOrPath))
    ) {
      //打印图片
      //printJs(urlOrPath, "image");
      // or
      printJs({
        printable: urlOrPath,
        type: "image",
        onPrintDialogClose: () => {
          //console.log("print dialog closed")
          resolve();
        },
      });
    } else if (
      printType == "pdf" ||
      (!printType && urlHelper.isPdfUrl(urlOrPath))
    ) {
      //打印pdf
      // let url = /^(blob|http|\/\/)/i.test(urlOrPath)
      //     ? urlOrPath
      //     : window.location.origin + (urlOrPath.charAt(0) !== '/' ? '/' + urlOrPath : urlOrPath);
      let url = /^(blob|http|\/\/)/i.test(urlOrPath)
        ? urlOrPath
        : urlHelper.combineURLs(window.location.origin, urlOrPath);

      let req = new window.XMLHttpRequest();
      if (req.mock && window._XMLHttpRequest) {
        req = new window._XMLHttpRequest();
      }
      req.responseType = "arraybuffer";
      req.addEventListener("error", () => {
        reject();
        // Since we don't have a pdf document available, we will stop the print job
      });

      req.addEventListener("load", () => {
        // Check for errors
        if ([200, 201].indexOf(req.status) === -1) {
          reject();
          // Since we don't have a pdf document available, we will stop the print job
          return;
        }
        // Print requested document
        printJs({
          printable: window.btoa(
            chunkFromCharCode([...new Uint8Array(req.response)])
            // String.fromCharCode(...new Uint8Array(req.response))
          ),
          type: "pdf",
          base64: true,
          onPrintDialogClose: () => {
            //console.log("print dialog closed")
            resolve();
          },
        });
      });
      req.open("GET", url, true);

      if (isApiHost(url)) {
        let token = getToken();
        if (token) {
          req.setRequestHeader(
            headerKeys.authorization,
            headerKeys.bearer + " " + token
          );
        }

        let culture = getLang();
        if (culture) {
          req.setRequestHeader(headerKeys.customCulture, culture);
        }
      }
      req.send();
    } else {
      //not supported
      reject();
    }
  });
};

export default print;
