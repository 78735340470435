import client from "../../client";

const Api = {
  //国家
  getCountrys: "/api/BasicDataService/Country/GetList",
  getGlobalCountrys: "/api/BasicDataService/Country/GetAllByParams", //全球的国家

  getProvinces: "/api/BasicDataService/Province/GetListByCountryId",
  getCitys: "/api/BasicDataService/City/GetListByProvinceId",
  getCountys: "/api/BasicDataService/County/GetListByCityId",

  //搜索城市
  searchCities: "/api/BasicDataService/City/GetListPaged",

  //币种
  getCurrencys: "/api/BasicDataService/Currency/GetList",

  //时区
  getTimezones: "/api/BasicDataService/CustomTimeZone/GetList",

  //常用地址
  getCommonAddress: "/api/ProductService/CommonContacter/GetList",
  createCommonAddress: "/api/ProductService/CommonContacter/Create",
  updateCommonAddress: "/api/ProductService/CommonContacter/Update",
  uploadImg: "/api/BasicDataService/AliyunOSS/UploadToTemp",

  //获得仓库列表
  getWarehouses: "/api/WarehouseService/Warehouse/GetList",

  //获得下拉框仓库列表
  getOptionWarehouses: "/api/WarehouseService/Warehouse/GetSelectOptionList",

  //获得仓库列表-配送仓库列表(当前用户有库存的)
  getUserWarehouseWithStock:
    "/api/WarehouseService/ProductInventory/GetInventoryWarehouse",

  //获得账户的余额
  getAccountBalance: "/api/ProductService/Balance/GetByCurrencyId",

  //更新用户自定义的箱号
  updateUserDefinedBoxNo: "/api/WarehouseService/Box/UpdateUserSelfBoxNo",
  // 产品统计-入库
  getInWarehouseTotalPlanProduct: "/api/WarehouseService/InWarehousePlan/GetInWarehouseTotalPlanProduct",
  // 产品统计-出库
  getOutWarehouseTotalPlanProduct: "/api/WarehouseService/OutWarehousePlan/GetOutWarehouseTotalPlanProduct",
  // 产品统计-运输计划
  getSummary: "/api/WarehouseService/TransportPlan/GetSummary",
  
  // 物流方案-卡车-报价类型
  getReceivingChannelsList: "/api/SupplierService/Supplier/GetReceivingChannelsList",
  // 物流方案-卡车-服务等级
  getServiceLevel: "/api/SupplierService/Supplier/GetServiceLevel",
  // 物流方案-卡车-附加选项
  getAdditionalOptions: "/api/SupplierService/Supplier/GetAdditionalOptions",

  //auto产品搜索(下拉框)
  getAutoProductList: "/api/ProductService/Product/GetAutoListPaged",

  //获得箱内产品
  getBoxOfProductsByWhboxId:"/api/WarehouseService/Box/GetBoxProductListByWhBoxId",

  // 检查邮编是否有效
  checkZipCodeValid: "/api/BasicDataService/AreaZipcodeRelation/CheckZipCodeValid",
};

//查启用的国家
export const getCountrys = (data) => {
  return client.request({
    url: Api.getCountrys,
    params: data,
    method: "get",
  });
};

//查全球所有国家
export const getGlobalCountrys = () => {
  return client.request({
    url: Api.getGlobalCountrys,
    data: {},
    method: "post",
  });
};

export const getProvinces = (data) => {
  return client.request({
    url: Api.getProvinces,
    params: data,
    method: "get",
  });
};

export const getCitys = (data) => {
  return client.request({
    url: Api.getCitys,
    params: data,
    method: "get",
  });
};

export const getCountys = (data) => {
  return client.request({
    url: Api.getCountys,
    params: data,
    method: "get",
  });
};

//币种
export const getCurrencys = (data) => {
  return client.request({
    url: Api.getCurrencys,
    params: data,
    method: "get",
  });
};

//获取时区
export const getTimezones = ({ searchKey, isActive = true, ids = [] }) => {
  return client.request({
    url: Api.getTimezones,
    data: { searchKey, isActive, ids },
    method: "post",
  });
};

export const getCommonAddress = (data) => {
  return client.request({
    url: Api.getCommonAddress,
    data: data,
    method: "post",
  });
};

export const createCommonAddress = (data) => {
  return client.request({
    url: Api.createCommonAddress,
    data: data,
    method: "post",
  });
};
export const updateCommonAddress = (data) => {
  return client.request({
    url: Api.updateCommonAddress,
    data: data,
    method: "post",
  });
};

export const uploadImg = (data) => {
  return client.request({
    url: Api.uploadImg,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getWarehouses = (data) => {
  return client.request({
    url: Api.getWarehouses,
    data: data,
    method: "post",
  });
};

//warehouseType 1 : 存储仓, 2 : 配送仓, 4 : 物流中心
export const getOptionWarehouses = ({
  countryId,
  provinceId,
  warehouseType,
  searchKey,
  warehouseNo,
  isActive = true,
}) => {
  return client.request({
    url: Api.getOptionWarehouses,
    data: {
      countryId,
      provinceId,
      warehouseType,
      searchKey,
      warehouseNo,
      isActive,
    },
    method: "post",
  });
};

export const searchCities = ({
  countryId,
  provinceId,
  searchKey,
  maxResultCount,
  sorting,
  skipCount,
}) => {
  return client.request({
    url: Api.searchCities,
    data: {
      countryId,
      provinceId,
      searchKey,
      maxResultCount,
      sorting,
      skipCount,
    },
    method: "post",
  });
};

export const getUserWarehouseWithStock = () => {
  return client.request({
    url: Api.getUserWarehouseWithStock,
    data: {},
    method: "post",
  });
};

export const getAccountBalance = ({ currencyId }) => {
  return client.request({
    url: Api.getAccountBalance,
    data: {
      currencyId,
    },
    method: "post",
  });
};

export const updateUserDefinedBoxNo = ( whboxId,selfBoxNo ) => {
  return client.request({
    url: Api.updateUserDefinedBoxNo,
    data:{ whboxId,selfBoxNo },
    method: "post",
  });
};

export const getInWarehouseTotalPlanProduct = (data) => {
  return client.request({
    url: Api.getInWarehouseTotalPlanProduct,
    params: data,
    method: "GET",
  });
};

export const getOutWarehouseTotalPlanProduct = (data) => {
  return client.request({
    url: Api.getOutWarehouseTotalPlanProduct,
    params: data,
    method: "GET",
  });
};

export const getSummary = (data) => {
  return client.request({
    url: Api.getSummary,
    data,
    method: "POST",
  });
};

export const getReceivingChannelsList = () => {
  return client.request({
    url: Api.getReceivingChannelsList,
    method: "GET",
  });
};

export const getServiceLevel = () => {
  return client.request({
    url: Api.getServiceLevel,
    method: "GET",
  });
};

export const getAdditionalOptions = () => {
  return client.request({
    url: Api.getAdditionalOptions,
    method: "GET",
  });
};

export const getAutoProductList = ({
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getAutoProductList,
    data: {
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const getBoxOfProductsByWhboxId = (whboxId) => {
  return client.request({
    url: Api.getBoxOfProductsByWhboxId,
    params: {whboxId},
    method: "get",
  });
};

export const checkZipCodeValid = (data) => {
  return client.request({
    url: Api.checkZipCodeValid,
    data,
    method: "POST",
  });
};