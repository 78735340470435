<template>
    <div style="cursor: pointer; user-select: none; display:inline-block;" @click="handleClick">
      <Icon v-if="disabled" style="color: #a1a1a1;" >
        <template #component>
          <IconBox width="1em" height="1em" style=" font-size: 32px;"/>
        </template>
      </Icon>
      <template v-else>
      <Icon v-if="open" class="text-primary" viewBox="0 0 1024 1024">
        <template #component>
          <IconBoxOpened width="1em" height="1em" style=" font-size: 32px;"/>
        </template>
      </Icon>
      <Icon v-else class="text-primary" viewBox="0 0 1024 1024">
        <template #component>
          <IconBox width="1em" height="1em" style=" font-size: 32px;"/>
        </template>
      </Icon>
      </template>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue';
import IconBox from './icons/IconBox.vue';
import IconBoxOpened from './icons/IconBoxOpened.vue';

export default defineComponent({
  emits:["click"],
  components: {
    Icon,
    IconBox,
    IconBoxOpened,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const handleClick = (e) => {
      if (props.disabled) {
        e.stopPropagation();        
        return;
      }
      emit("click", e);
    }
    return {
      handleClick,
    }
  }
})
</script>

<style scoped>

</style>